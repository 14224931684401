<script>
import DisciplinesService from '@/service/disciplines.service';

export default {
  name: 'list-disciplines',

  components: {
    ModalRegisterDiscipline: () => import('./ModalRegisterDiscipline.vue'),
  },

  data() {
    return {
      loading: false,
      options: {
        sortBy: ['name'],
        sortDesc: [false],
      },

      totalItems: 0,
      items: [],

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Nome', value: 'name' },
        { text: 'Extracurricular', value: 'extracurricular' },
        { text: 'Ações', align: 'center', value: 'actions' },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },
  },

  methods: {
    async fetch() {
      this.loading = true;

      try {
        const filter = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
        };

        if (this.options.sortBy.length > 0) {
          filter.sortBy = `${this.options.sortBy[0]}:${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        const { data, meta } = await DisciplinesService.find(filter);
        this.items = data;
        this.page = meta?.currentPage;
        this.totalItems = meta?.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    create() {
      this.$refs.modalRegister.$emit('create');
    },

    edit(discipline) {
      this.$refs.modalRegister.$emit('edit', discipline.id);
    },

    remove(discipline) {
      this.$swal({
        text: `Deseja remover '${discipline.name}'?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return DisciplinesService.delete(discipline.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Dispositivo removido com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <v-row class="pb-3">
      <v-col cols="12" sm="4">
        <h5 class="pt-4">Disciplinas</h5>
      </v-col>
      <v-col cols="12" sm="8" class="text-right">
        <v-btn color="primary" @click="create">Novo</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 30, 50] }"
      class="elevation-3 no-line-row"
    >
      <template v-slot:[`item.extracurricular`]="{ item }">
        {{ item.extracurricular === true ? 'Sim' : 'Não' }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon title="Editar" @click="edit(item)">
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
        <v-btn icon color="error" title="Remover" @click="remove(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <modal-register-discipline ref="modalRegister" @success="fetch" />
  </div>
</template>
